var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "upload-accident-photos" },
    [
      _c(
        "div",
        { staticClass: "header-btns-container" },
        [
          _c("b-button", { attrs: { label: "שמור" }, on: { click: _vm.save } }),
          _c("b-button", {
            attrs: { label: "סגור" },
            on: {
              click: function($event) {
                return _vm.$emit("close")
              }
            }
          })
        ],
        1
      ),
      _c("div", { staticClass: "opt-btns-container" }, [
        _vm.uploadCase.includes(0)
          ? _c(
              "div",
              [
                _c("label", [_vm._v("ביטוח חובה")]),
                !_vm.checkIfAdded(0)
                  ? _c("b-button", {
                      attrs: {
                        "icon-left": _vm.checkIfAdded(0) ? "check" : "camera",
                        size: "is-large"
                      },
                      on: {
                        click: function($event) {
                          return _vm.sendToCamera([2, 0])
                        }
                      }
                    })
                  : _c("canvas", {
                      ref: "canvas0",
                      staticClass: "canvas",
                      attrs: { width: 100, height: 130 },
                      on: {
                        click: function($event) {
                          return _vm.sendToCamera([2, 0])
                        }
                      }
                    })
              ],
              1
            )
          : _vm._e(),
        _vm.uploadCase.includes(1)
          ? _c(
              "div",
              [
                _c("label", [_vm._v("רישיון נהיגה")]),
                !_vm.checkIfAdded(1)
                  ? _c("b-button", {
                      attrs: {
                        "icon-left": _vm.checkIfAdded(1) ? "check" : "camera",
                        size: "is-large"
                      },
                      on: {
                        click: function($event) {
                          return _vm.sendToCamera([3, 1])
                        }
                      }
                    })
                  : _c("canvas", {
                      ref: "canvas1",
                      staticClass: "canvas",
                      attrs: { width: 100, height: 130 },
                      on: {
                        click: function($event) {
                          return _vm.sendToCamera([3, 1])
                        }
                      }
                    })
              ],
              1
            )
          : _vm._e(),
        _vm.uploadCase.includes(2)
          ? _c(
              "div",
              [
                _c("label", [_vm._v("רישיון רכב")]),
                !_vm.checkIfAdded(2)
                  ? _c("b-button", {
                      attrs: {
                        "icon-left": _vm.checkIfAdded(2) ? "check" : "camera",
                        size: "is-large"
                      },
                      on: {
                        click: function($event) {
                          return _vm.sendToCamera([1, 2])
                        }
                      }
                    })
                  : _c("canvas", {
                      ref: "canvas2",
                      staticClass: "canvas",
                      attrs: { width: 100, height: 130 },
                      on: {
                        click: function($event) {
                          return _vm.sendToCamera([1, 2])
                        }
                      }
                    })
              ],
              1
            )
          : _vm._e(),
        _vm.uploadCase.includes(3)
          ? _c(
              "div",
              [
                _c("label", [_vm._v("נזק")]),
                !_vm.checkIfAdded(3)
                  ? _c("b-button", {
                      attrs: {
                        "icon-left": _vm.checkIfAdded(3) ? "check" : "camera",
                        size: "is-large"
                      },
                      on: {
                        click: function($event) {
                          return _vm.sendToCamera(
                            _vm.localhostName === "myDamage" ? [0, 3] : [4, 3]
                          )
                        }
                      }
                    })
                  : _c("canvas", {
                      ref: "canvas3",
                      staticClass: "canvas",
                      attrs: { width: 100, height: 130 },
                      on: {
                        click: function($event) {
                          return _vm.sendToCamera(
                            _vm.localhostName === "myDamage" ? [0, 3] : [4, 3]
                          )
                        }
                      }
                    })
              ],
              1
            )
          : _vm._e(),
        _vm.uploadCase.includes(3)
          ? _c(
              "div",
              [
                _c("label", [_vm._v("נזק")]),
                !_vm.checkIfAdded(4)
                  ? _c("b-button", {
                      attrs: {
                        "icon-left": _vm.checkIfAdded(4) ? "check" : "camera",
                        size: "is-large"
                      },
                      on: {
                        click: function($event) {
                          return _vm.sendToCamera(
                            _vm.localhostName === "myDamage" ? [0, 4] : [4, 4]
                          )
                        }
                      }
                    })
                  : _c("canvas", {
                      ref: "canvas4",
                      staticClass: "canvas",
                      attrs: { width: 100, height: 130 },
                      on: {
                        click: function($event) {
                          return _vm.sendToCamera(
                            _vm.localhostName === "myDamage" ? [0, 4] : [4, 4]
                          )
                        }
                      }
                    })
              ],
              1
            )
          : _vm._e(),
        _vm.uploadCase.includes(3)
          ? _c(
              "div",
              [
                _c("label", [_vm._v("נזק")]),
                !_vm.checkIfAdded(5)
                  ? _c("b-button", {
                      attrs: {
                        "icon-left": _vm.checkIfAdded(5) ? "check" : "camera",
                        size: "is-large"
                      },
                      on: {
                        click: function($event) {
                          return _vm.sendToCamera(
                            _vm.localhostName === "myDamage" ? [0, 5] : [4, 5]
                          )
                        }
                      }
                    })
                  : _c("canvas", {
                      ref: "canvas5",
                      staticClass: "canvas",
                      attrs: { width: 100, height: 130 },
                      on: {
                        click: function($event) {
                          return _vm.sendToCamera(
                            _vm.localhostName === "myDamage" ? [0, 5] : [4, 5]
                          )
                        }
                      }
                    })
              ],
              1
            )
          : _vm._e(),
        _vm.uploadCase.includes(3)
          ? _c(
              "div",
              [
                _c("label", [_vm._v("נזק")]),
                !_vm.checkIfAdded(6)
                  ? _c("b-button", {
                      attrs: {
                        "icon-left": _vm.checkIfAdded(6) ? "check" : "camera",
                        size: "is-large"
                      },
                      on: {
                        click: function($event) {
                          return _vm.sendToCamera(
                            _vm.localhostName === "myDamage" ? [0, 6] : [4, 6]
                          )
                        }
                      }
                    })
                  : _c("canvas", {
                      ref: "canvas6",
                      staticClass: "canvas",
                      attrs: { width: 100, height: 130 },
                      on: {
                        click: function($event) {
                          return _vm.sendToCamera(
                            _vm.localhostName === "myDamage" ? [0, 6] : [4, 6]
                          )
                        }
                      }
                    })
              ],
              1
            )
          : _vm._e()
      ]),
      _vm.isCameraOpen
        ? _c("Camera", {
            attrs: {
              isCamera: _vm.isCameraOpen,
              currType: _vm.currType,
              cameraNum: _vm.cameraNum
            },
            on: {
              closeCamera: function($event) {
                _vm.isCameraOpen = false
              },
              addPhotoObj: _vm.addPhotoObj
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }