<template>
  <div class="PhoneAccident__Component">
    <div v-if="frame === 0" class="frame-0">
      <h1>שלום נהג יקר</h1>
      <h1>הינך עומד למלא טופס תאונה</h1>
      <h1>יש למלא מספר טלפון כדי להמשיך</h1>
      <div class="frame-0-input-btn">
        <FieldInlineText
          label="הכנס מספר טלפון:"
          @onChange="onChange"
          :readOnly="false"
          v-model="phoneNumber"
          type="tel"
          borderBottom="1px solid white"
        />
        <b-button label="המשך" @click="varifyPhone" />
      </div>
    </div>
    <div v-if="frame === 1" class="frame-1">
      <h1>נא הכנס קוד שקיבלת בהודעה למספר נייד {{ model.phoneNumber }}</h1>
      <div class="frame-1-input-btn">
        <FieldInlineText
          label="הכנס קוד:"
          @onChange="onChange"
          :readOnly="false"
          v-model="sms"
          type="tel"
          borderBottom="1px solid white"
        />

        <div class="frame-1-btns-container">
          <b-button label="המשך" @click="varifySms" />
          <a @click="varifyPhone">שלח שוב</a>
        </div>
      </div>
    </div>
    <div v-if="frame === 2" class="frame-2">
      <h1>{{ driverName }} נא בחר סוג תאונה</h1>
      <div class="accident-types">
        <div v-for="accident in accidentOpt" :key="accident.Value">
          <label for=""> {{ accident.Text }} </label>
          <b-button
            :style="{
              backgroundColor: getBackground(accident.Value, 'color'),
              backgroundImage: getBackground(accident.Value, 'icon'),
            }"
            @click="onSelect(accident)"
          />
        </div>
      </div>
    </div>
    <div class="frame-3" v-if="frame === 3">
      <b-button label="חזור" @click="frame = 2" />
      <h1 style="text-align: center; color: white">
        {{ this.model.AccidentType.Text }}
      </h1>
      <FieldInlineText
        label="מספר רכב"
        @onChange="onChange"
        :readOnly="true"
        :value="getPlateNumber"
      />

      <FieldInlineDateTime
        label="תאריך ושעת האירוע"
        @onChange="onChange"
        :readOnly="readOnly"
        v-model="model.Date"
        position="is-bottom-left"
        :borderBottom="true"
      />
      <FieldInlineText
        label="מיקום ההתרחשות"
        @onChange="onChange"
        :readOnly="readOnly"
        v-model="model.Address"
        borderBottom="01px solid white"
      />
      <b-button
        class="my-location-btn"
        icon-right="map-marker"
        @click="getMyCurrLoc"
      />
      <div class="btns-pics-containar">
        <b-button
          label="תמונות נזק- רכב שלי"
          :icon-left="validateMyPhotos ? 'check' : ''"
          @click="openMyDamageModal([1, 3])"
          v-if="!isLoading && uploadeFiles.includes(0)"
        />
        <b-button
          label="פרטי רכב צד ג'"
          :icon-left="validateThirdSideDetailsPhotos ? 'check' : ''"
          @click="openMyDamageModal([0, 1, 2])"
          v-if="!isLoading && uploadeFiles.includes(1)"
        />
        <b-button
          label="תמונות נזק צד ג'"
          :icon-left="validateThirdSideDamagePhotos ? 'check' : ''"
          @click="openMyDamageModal([3])"
          v-if="!isLoading && uploadeFiles.includes(2)"
        />
      </div>
      <FieldInlineTextArea
        label="תיאור המקרה"
        @onChange="onChange"
        :readOnly="readOnly"
        v-model="model.Description"
      />
      <b-button
        v-if="!isLoading"
        style="margin: 0 0 0 20px"
        :icon-left="isSent ? 'check' : ''"
        label="שמור"
        @click="saveAndUpload"
      />
      <b-button
        label="הוסף חתימה"
        v-if="!isLoading && isReadyToSign"
        @click="toSign"
      />
    </div>
    <div class="frame-4" v-if="frame === 4">
      <h1>תאונה הועלתה בהצלחה</h1>
    </div>
    <UploadAccidentPhotos
      v-if="isUploadAccidentPhotos"
      :uploadCase="uploadCase"
      :uploadeFiles="uploadeFiles"
      :AccidentType="model.AccidentType"
      @uploadAndClose="uploadAndClose"
      @close="isUploadAccidentPhotos = false"
    />
  </div>
</template>

<script>
import FieldInlineDateTime from "@/components/Fields/FieldInlineDateTime.vue";
import UploadAccidentPhotos from "@/components/UploadAccidentPhotos.vue";
import FieldInlineText from "@/components/Fields/FieldInlineText.vue";
import FieldInlineTextArea from "@/components/Fields/FieldInlineTextArea.vue";
import DialogSign from "@/components/DialogSign.vue";
import { mapGetters, mapMutations } from "vuex";
import { required } from "vuelidate/lib/validators";
import axios from "axios";
import { ModalProgrammatic as Modal } from "buefy";
import green from "../assets/green.png";
import yellow from "../assets/yellow.png";
import blue from "../assets/blue.png";
// eslint-disable-next-line import/no-unresolved
import red from "../assets/red.png";
import AccidentService from "../services/AccidentService";

export default {
  name: "PhoneAccident",
  components: {
    UploadAccidentPhotos,
    FieldInlineText,
    FieldInlineDateTime,
    FieldInlineTextArea,
  },
  props: ["readOnly"],
  data() {
    return {
      frame: 0,
      uploadeFiles: [],
      isLoading: false,
      isUploadAccidentPhotos: false,
      uploadCase: [],
      myVehicle: [],
      thirdDamage: [],
      thirdDetails: [],
      driverName: "",
      accidentId: null,
      isShowSign: false,
      isReadyToSign: false,
      isSent: false,
      model: {
        AccidentType: null,
        Address: null,
        Date: null,
        Description: null,
        PlateNumber: null,
      },
      phoneNumber: 972527978002,
      sms: null,
      accidentOpt: [
        { Value: 0, Text: "תאונת דרכים | אני הפוגע" },
        { Value: 1, Text: "תאונת דרכים | אני הנפגע" },
        { Value: 2, Text: "נזק עצמי" },
        { Value: 3, Text: "נזק עצמי | נזק לרכוש" },
      ],
    };
  },
  validations: {
    phoneNumber: { required },
    sms: { required },
  },
  computed: {
    ...mapGetters("accident", ["form"]),
    getPlateNumber() {
      return this.$route.params.plateNumber;
    },
    validateMyPhotos() {
      const myVehicleTypes = [0, 3];
      const ImgTypes = this.myVehicle.map((img) => img.ImageType);
      return myVehicleTypes.every((r) => ImgTypes.indexOf(r) > -1);
    },
    validateThirdSidePhotos() {
      const arrOther = [];
      switch (this.model.AccidentType.Value) {
        case 0:
          // eslint-disable-next-line no-unused-expressions
          arrOther.push(1, 2, 3, 4);
          break;
        case 1:
          // eslint-disable-next-line no-unused-expressions
          arrOther.push(1, 2, 3, 4);
          break;
        case 2:
          // eslint-disable-next-line no-unused-expressions
          break;
        case 3:
          // eslint-disable-next-line no-unused-expressions
          arrOther.push(4);
          break;
        default:
          return null;
      }
      const otherVehicle = this.thirdDetails.concat(this.thirdDamage);
      const ImgTypes = otherVehicle.map((img) => img.ImageType);
      return arrOther.every((r) => ImgTypes.indexOf(r) > -1);
    },
    validateThirdSideDetailsPhotos() {
      const arrOtherDetails = [];
      switch (this.model.AccidentType.Value) {
        case 0:
          // eslint-disable-next-line no-unused-expressions
          arrOtherDetails.push(1, 2, 3);
          break;
        case 1:
          // eslint-disable-next-line no-unused-expressions
          arrOtherDetails.push(1, 2, 3);
          break;
        case 2:
          // eslint-disable-next-line no-unused-expressions
          break;
        case 3:
          // eslint-disable-next-line no-unused-expressions
          arrOtherDetails.push(4);
          break;
        default:
          return null;
      }
      const otherVehicle = this.thirdDetails.concat(this.thirdDamage);
      const ImgTypes = otherVehicle.map((img) => img.ImageType);
      return arrOtherDetails.every((r) => ImgTypes.indexOf(r) > -1);
    },
    validateThirdSideDamagePhotos() {
      const arrOtherDetails = [];
      switch (this.model.AccidentType.Value) {
        case 0:
          // eslint-disable-next-line no-unused-expressions
          arrOtherDetails.push(4);
          break;
        case 1:
          // eslint-disable-next-line no-unused-expressions
          arrOtherDetails.push(4);
          break;
        case 2:
          // eslint-disable-next-line no-unused-expressions
          break;
        case 3:
          arrOtherDetails.push(4);
          // eslint-disable-next-line no-unused-expressions
          break;
        default:
          return null;
      }
      const otherVehicle = this.thirdDetails.concat(this.thirdDamage);
      const ImgTypes = otherVehicle.map((img) => img.ImageType);
      return arrOtherDetails.every((r) => ImgTypes.indexOf(r) > -1);
    },
    changesAmount() {
      return Object.keys(this.diff(this.model, this.form)).length;
    },
  },
  created() {
    this.model = { ...this.model, ...this.form };
  },
  methods: {
    getBackground(val, bg) {
      // eslint-disable-next-line default-case
      switch (val) {
        // eslint-disable-next-line no-param-reassign
        case 0:
          return bg === "icon" ? `url(${yellow})` : `#E5B716`;
        // eslint-disable-next-line no-param-reassign
        case 1:
          return bg === "icon" ? `url(${red})` : `#F56161`;
        // return `#F56161`;
        // eslint-disable-next-line no-param-reassign
        case 2:
          return bg === "icon" ? `url(${blue})` : `#257CB0`;
        // return `#257CB0`;
        // eslint-disable-next-line no-param-reassign
        case 3:
          return bg === "icon" ? `url(${green})` : `#7CC576`;
        // return `#7CC576`;
        default:
          return null;
      }
    },
    async convertAndUpload() {
      let otherVehicle = this.thirdDetails.concat(this.thirdDetails);
      otherVehicle = otherVehicle.map((img) => ({
        ImageType: img.ImageType,
        ImageUrl: img.imgUrl,
        Side: 0,
      }));
      this.myVehicle = this.myVehicle.map((img) => ({
        ImageType: img.ImageType,
        ImageUrl: img.imgUrl,
        Side: 0,
      }));
      const objToApi = {
        Date: this.model.Date,
        Address: this.model.Address,
        PlateNumber: this.getPlateNumber,
        Description: this.model.Description,
        AccidentType: this.model.AccidentType.Value,
        MyVehicle: this.myVehicle,
        OtherVehicle: otherVehicle,
      };
      const accidentDetails = await AccidentService.uploadAccident(objToApi);
      this.accidentId = accidentDetails.data.Id;
      this.isSent = true;
      this.isReadyToSign = true;

      localStorage.removeItem("myDamage");
      localStorage.removeItem("thirdDamage");
      localStorage.removeItem("thirdDetails");
    },
    uploadAndClose({ r, localhostName }) {
      Promise.all(r).then((arr) => {
        if (localhostName === "myDamage") this.myVehicle = arr;
        else if (localhostName === "thirdDamage") this.thirdDamage = arr;
        else if (localhostName === "thirdDetails") this.thirdDetails = arr;
        localStorage.setItem(localhostName, JSON.stringify(arr));
        this.isUploadAccidentPhotos = false;
      });
    },
    openMyDamageModal(r) {
      this.uploadCase = r;
      this.isUploadAccidentPhotos = true;
    },
    async getMyCurrLoc() {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          console.log(position.coords.latitude);
          console.log(position.coords.longitude);
          axios
            .get(
              `https://maps.googleapis.com/maps/api/geocode/json?latlng=${position.coords.latitude},${position.coords.longitude}&key=AIzaSyAndV9yjOVvrOG0tmDCpfsvLPBNAply6dc`
            )
            .then(({ data }) => {
              if (data.error_message) {
                console.log(data.error_message);
              } else {
                this.address = data.results[0].formatted_address;
              }
            });
        },
        (error) => {
          console.log(error.message);
        }
      );
    },
    varifyPhone() {
      AccidentService.varifyPhone(this.phoneNumber)
        .then((r) => {
          this.sms = r.data.Value;
          this.frame = 1;
        })
        .catch(() => {
          let message = null;
          if (!this.phoneNumber) {
            message = "לא הוזן מספר";
          } else {
            message = `המספר ${this.phoneNumber} לא נמצא במערכת.<br>נא בדוק שנית`;
          }
          this.$buefy.toast.open({
            duration: 8000,
            message,
            position: "is-bottom",
            type: "is-warning",
          });
        });
    },
    varifySms() {
      const model = {
        code: this.sms,
        number: this.phoneNumber,
      };
      AccidentService.varifySms(model)
        .then((r) => {
          console.log("r:", r);
          this.driverName = r.data.userName;
          this.$auth.handleAuthentication(r.data).then(() => (this.frame = 2));
        })
        .catch(() => {
          if (!this.sms) {
            console.log(`didnt entered number yet,number is: ${this.sms}`);
          } else console.log(`sms was not found: ${this.sms}`);
        });
    },

    showDocument() {
      this.$iframe.open({
        html: this.html,
        // type: this.widget.documentType,
        onSave: async ({ data, close }) => {
          if (data.close) {
            close();
          } else {
            this.saveDocument(data);
            close();
          }
        },
      });
    },
    diff(o1, o2) {
      const diff = Object.keys(o2).reduce((diff1, key) => {
        if (o1[key] && typeof o1[key].getMonth === "function") {
          if (new Date(o1[key]).toString() === new Date(o2[key]).toString()) {
            return diff1;
          }
        }
        if (typeof o1[key] === "number") {
          if (parseInt(o1[key], 10) === parseInt(o2[key], 10)) return diff1;
        }
        if (
          o1[key] &&
          typeof o1[key] === "object" &&
          typeof o1[key].getMonth !== "function"
        ) {
          if (o1[key]?.Id === o2[key]?.Id) return diff1;
        }
        if (o1[key] === o2[key]) return diff1;
        return {
          ...diff1,
          [key]: o2[key],
        };
      }, {});

      return diff;
    },
    onSelect(selected) {
      this.model.AccidentType = selected;
      switch (selected.Value) {
        case 0:
          this.uploadeFiles = [0, 1, 2]; // תאונת דרכים אני הפוגע
          return (this.frame = 3);
        case 1:
          this.uploadeFiles = [0, 1, 2]; // תאונת דרכים אני הנפגע
          return (this.frame = 3);
        case 2:
          this.uploadeFiles = [0]; // נזק עצמי
          return (this.frame = 3);
        case 3:
          this.uploadeFiles = [0, 2]; // נזק עצמי נזק לרכוש
          return (this.frame = 3);
        default:
          return null;
      }
    },
    onChange(c) {
      console.log("c:", c);
      //   this.$emit("onFormChange", this.changesAmount);
    },
    cancel() {
      this.model = { ...this.model, ...this.form };
    },
    toSign() {
      this.isShowSign = true;
      Modal.open({
        component: DialogSign,
        props: {
          onSuccess: () => {
            this.isShowSign = false;
            this.frame = 4;
          },
          accidentId: this.accidentId,
          title: "חתימה על דוח תאונה",
          signCase: "driverAccidentSign",
        },
        canCancel: false,
        hasModalCard: true,
        trapFocus: true,
      });
    },
    async saveAndUpload() {
      if (
        this.model.Date &&
        this.model.Address &&
        this.model.Description &&
        this.getPlateNumber &&
        this.validateThirdSidePhotos &&
        this.validateMyPhotos
      ) {
        this.convertAndUpload()
          .then(() => {
            this.$buefy.toast.open({
              duration: 3000,
              message: `כבר מסיימים...<br>הוסף חתימה`,
              position: "is-bottom",
              type: "is-success",
            });
          })
          .catch(() => {
            this.$buefy.toast.open({
              duration: 8000,
              message: `חלה תקלה בשליחת הדוח`,
              position: "is-bottom",
              type: "is-warning",
            });
          });
      } else {
        this.$buefy.toast.open({
          duration: 8000,
          message: `יש למלא את כל השדות`,
          position: "is-bottom",
          type: "is-warning",
        });
      }
    },
    save() {
      this.$emit("onFormSave", {
        model: this.model,
        cancel: this.cancel,
        setRestorePoint: this.setRestorePoint,
      });
    },
    setRestorePoint() {
      this.setData(this.model);
    },
    refresh() {
      this.model = { ...this.model, ...this.form };
    },
    ...mapMutations("accident", ["setData"]),
  },
};
</script>

<style lang="scss">
.PhoneAccident__Component {
  // margin: 1vh 1vw;
  // border: 1px black solid;
  // height: 98vh;
  padding: 20px;
  min-height: 200px;
  height: 100vh;
  background-color: #7aa9b1;
  // overflow-y: auto;

  input {
    line-height: 50px;
  }
  input,
  textarea {
    background-color: #7aa9b1;
    color: white;
  }
  textarea {
    border: 1px white solid !important;
    border-radius: 10px !important;
    padding: 5px;
    transform: translate(5px, -4px);
    background-color: initial;
  }
  .InlineField__Component__Placeholder__Text,
  ::placeholder,
  .InlineField__Component__Placeholder__Icon > .icon {
    color: white !important;
    border-bottom-color: white !important;
  }
  ::-webkit-resizer {
    display: none;
  }
  input:hover,
  input:focus {
    border-bottom-color: white;
  }
  .frame-0 {
    .frame-0-input-btn {
      display: flex;
      margin-top: 100px;
      flex-direction: column;
      height: 150px;
      justify-content: space-around;
    }
  }
  .frame-1 {
    .frame-1-input-btn {
      display: flex;
      margin-top: 100px;
      flex-direction: column;
      height: 150px;
      justify-content: space-around;
      > :first-child {
        ::v-deep input {
          border-bottom: 1px solid white !important;
          background-color: red !important;
        }
      }
      .frame-1-btns-container {
        display: flex;
        align-items: stretch;
        flex-direction: column;
        align-content: flex-end;
        justify-content: space-evenly;
        a {
          margin-top: 10px;
          color: white;
          width: 57px;
        }
      }
    }
  }

  .frame-2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: white;
    h1 {
      padding-top: 20px;
      font-size: 22px;
      font-weight: 400;
    }
    .accident-types {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-evenly;
      margin-top: 100px;
      height: 75vw;
      & > div {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 30px;
      }
      div > *:not(:first-child) {
        margin: 5px;
        width: 30vw;
        height: 30vw;
        background-size: 100% 100%;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
  .frame-3 {
    .my-location-btn {
      position: relative;
      float: left;
      top: -46px;
      border: none;
      background-color: #7aa9b1;
      color: white;
    }
    .btns-pics-containar {
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      width: 100%;
      > * {
        margin: 0 0 20px 0;
        width: 100%;
      }
    }
    .sign-modal-container {
      right: 0;
      background-color: #0000009c;
      width: 100vw;
      height: 100vh;
      top: 0;
      position: absolute;
      z-index: 10;
      .sign-modal {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
  .frame-4 {
    display: flex;
    width: 100%;
    height: 100%;
    align-content: stretch;
    justify-content: center;
    padding-top: 20vh;
    h1 {
      color: white;
      font-size: 2em;
    }
  }
}
</style>
