var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "web-camera-container" }, [
    _c("div", { class: _vm.isLoading ? "close-loading" : "close-camera-btn" }, [
      _c(
        "button",
        {
          staticClass: "button is-rounded",
          attrs: { type: "button" },
          on: { click: _vm.closeCamera }
        },
        [_c("b-icon", { attrs: { icon: "close", size: "is-large" } })],
        1
      )
    ]),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.isCameraOpen && _vm.isLoading,
            expression: "isCameraOpen && isLoading"
          }
        ],
        staticClass: "camera-loading"
      },
      [_vm._m(0)]
    ),
    _vm.isCameraOpen
      ? _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.isLoading,
                expression: "!isLoading"
              }
            ],
            staticClass: "camera-box",
            class: { flash: _vm.isShotPhoto }
          },
          [
            _c("div", {
              staticClass: "camera-shutter",
              class: { flash: _vm.isShotPhoto }
            }),
            _c("video", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.isPhotoTaken,
                  expression: "!isPhotoTaken"
                }
              ],
              ref: "camera",
              attrs: { autoplay: "", playsinline: "" }
            }),
            _c("canvas", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isPhotoTaken,
                  expression: "isPhotoTaken"
                }
              ],
              ref: "canvas",
              attrs: { id: "photoTaken", width: 324, height: 423 }
            })
          ]
        )
      : _vm._e(),
    _c("div", { staticClass: "camera-btns-container" }, [
      _vm.isCameraOpen && !_vm.isLoading
        ? _c("div", { staticClass: "camera-shoot" }, [
            _c(
              "button",
              {
                staticClass: "button",
                attrs: { type: "button" },
                on: { click: _vm.takePhoto }
              },
              [_c("b-icon", { attrs: { size: "is-large", icon: "camera" } })],
              1
            )
          ])
        : _vm._e(),
      _vm.isPhotoTaken && _vm.isCameraOpen
        ? _c("div", { staticClass: "camera-choose-btn" }, [
            _c("button", { on: { click: _vm.usePhoto } }, [
              _vm._v("השתמש בתמונה")
            ])
          ])
        : _vm._e()
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("ul", { staticClass: "loader-circle" }, [
      _c("li"),
      _c("li"),
      _c("li")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }