<template>
  <div class="upload-accident-photos">
    <div class="header-btns-container">
      <b-button label="שמור" @click="save" />
      <b-button label="סגור" @click="$emit('close')" />
    </div>
    <div class="opt-btns-container">
      <div v-if="uploadCase.includes(0)">
        <label>ביטוח חובה</label>
        <b-button
          v-if="!checkIfAdded(0)"
          @click="sendToCamera([2, 0])"
          :icon-left="checkIfAdded(0) ? 'check' : 'camera'"
          size="is-large"
        />
        <canvas
          v-else
          class="canvas"
          ref="canvas0"
          @click="sendToCamera([2, 0])"
          :width="100"
          :height="130"
        ></canvas>
      </div>
      <div v-if="uploadCase.includes(1)">
        <label>רישיון נהיגה</label>
        <b-button
          v-if="!checkIfAdded(1)"
          @click="sendToCamera([3, 1])"
          :icon-left="checkIfAdded(1) ? 'check' : 'camera'"
          size="is-large"
        />
        <canvas
          v-else
          class="canvas"
          @click="sendToCamera([3, 1])"
          ref="canvas1"
          :width="100"
          :height="130"
        ></canvas>
      </div>
      <div v-if="uploadCase.includes(2)">
        <label>רישיון רכב</label>
        <b-button
          v-if="!checkIfAdded(2)"
          @click="sendToCamera([1, 2])"
          :icon-left="checkIfAdded(2) ? 'check' : 'camera'"
          size="is-large"
        />
        <canvas
          v-else
          class="canvas"
          ref="canvas2"
          @click="sendToCamera([1, 2])"
          :width="100"
          :height="130"
        ></canvas>
      </div>
      <div v-if="uploadCase.includes(3)">
        <label>נזק</label>
        <b-button
          v-if="!checkIfAdded(3)"
          @click="sendToCamera(localhostName === 'myDamage' ? [0, 3] : [4, 3])"
          :icon-left="checkIfAdded(3) ? 'check' : 'camera'"
          size="is-large"
        />
        <canvas
          v-else
          class="canvas"
          ref="canvas3"
          @click="sendToCamera(localhostName === 'myDamage' ? [0, 3] : [4, 3])"
          :width="100"
          :height="130"
        ></canvas>
      </div>
      <div v-if="uploadCase.includes(3)">
        <label>נזק</label>
        <b-button
          v-if="!checkIfAdded(4)"
          @click="sendToCamera(localhostName === 'myDamage' ? [0, 4] : [4, 4])"
          :icon-left="checkIfAdded(4) ? 'check' : 'camera'"
          size="is-large"
        />
        <canvas
          v-else
          class="canvas"
          ref="canvas4"
          @click="sendToCamera(localhostName === 'myDamage' ? [0, 4] : [4, 4])"
          :width="100"
          :height="130"
        ></canvas>
      </div>
      <div v-if="uploadCase.includes(3)">
        <label>נזק</label>
        <b-button
          v-if="!checkIfAdded(5)"
          @click="sendToCamera(localhostName === 'myDamage' ? [0, 5] : [4, 5])"
          :icon-left="checkIfAdded(5) ? 'check' : 'camera'"
          size="is-large"
        />
        <canvas
          v-else
          class="canvas"
          ref="canvas5"
          @click="sendToCamera(localhostName === 'myDamage' ? [0, 5] : [4, 5])"
          :width="100"
          :height="130"
        ></canvas>
      </div>
      <div v-if="uploadCase.includes(3)">
        <label>נזק</label>
        <b-button
          v-if="!checkIfAdded(6)"
          @click="sendToCamera(localhostName === 'myDamage' ? [0, 6] : [4, 6])"
          :icon-left="checkIfAdded(6) ? 'check' : 'camera'"
          size="is-large"
        />
        <canvas
          v-else
          class="canvas"
          ref="canvas6"
          @click="sendToCamera(localhostName === 'myDamage' ? [0, 6] : [4, 6])"
          :width="100"
          :height="130"
        ></canvas>
      </div>
    </div>

    <Camera
      v-if="isCameraOpen"
      :isCamera="isCameraOpen"
      @closeCamera="isCameraOpen = false"
      @addPhotoObj="addPhotoObj"
      :currType="currType"
      :cameraNum="cameraNum"
    />
  </div>
</template>

<script>
import AccidentService from "../services/AccidentService";
import Camera from "./Camera.vue";

export default {
  props: ["uploadCase", "AccidentType", "uploadeFiles"],
  components: {
    Camera,
  },
  created() {
    if (JSON.stringify(this.uploadCase) === JSON.stringify([1, 3])) {
      this.localhostName = "myDamage";
    } else if (JSON.stringify(this.uploadCase) === JSON.stringify([0, 1, 2])) {
      this.localhostName = "thirdDetails";
    } else if (JSON.stringify(this.uploadCase) === JSON.stringify([3])) {
      this.localhostName = "thirdDamage";
    } else console.log("this.localhostName is empty!@!");
    this.imgs = JSON.parse(localStorage.getItem(this.localhostName)) || [];
    this.imgsAddedIdx = this.imgs.map((img) => {
      this.$nextTick(() => {
        this.placeCanvasFromStorage(img.cameraNum);
      });
      return img.cameraNum;
    });
  },
  data() {
    return {
      imgs: [],
      imgsAddedIdx: [],
      newImgsToUploadIdx: [],
      currType: null,
      isCameraOpen: false,
      localhostName: "",
    };
  },
  methods: {
    checkIfAdded(idx) {
      return this.imgsAddedIdx.some((cameraNum) => cameraNum === idx);
    },
    // eslint-disable-next-line consistent-return
    placeCanvas(idx) {
      if (!this.$refs[`canvas${idx}`]) {
        return console.log("elemnt not found element ");
      }
      const context = this.$refs[`canvas${idx}`].getContext("2d");
      const imgArrIdx = this.imgsAddedIdx.findIndex((i) => i === idx);
      const img = new Image();
      img.src = this.imgs[imgArrIdx].imgCanvas.toDataURL();
      img.onload = function () {
        context.drawImage(img, 0, 0, 100, 130);
      };
    },
    // eslint-disable-next-line consistent-return
    placeCanvasFromStorage(idx) {
      if (!this.$refs[`canvas${idx}`]) {
        return console.log("elemnt not found element ");
      }
      const context = this.$refs[`canvas${idx}`].getContext("2d");
      const imgArrIdx = this.imgsAddedIdx.findIndex((i) => i === idx);
      const img = new Image();
      img.src = this.imgs[imgArrIdx].imgCanvas;
      img.onload = function () {
        context.drawImage(img, 0, 0, 100, 130);
      };
    },
    addPhotoObj(obj) {
      const idx = this.imgsAddedIdx.findIndex((i) => i === obj.cameraNum);
      const { cameraNum } = obj;
      if (idx === -1) {
        this.imgsAddedIdx.push(obj.cameraNum);
        // delete obj.cameraNum;
        this.imgs.push(obj);
      } else {
        this.imgsAddedIdx.splice(idx, 1, obj.cameraNum);
        // delete obj.cameraNum;
        this.imgs.splice(idx, 1, obj);
      }
      this.isCameraOpen = false;
      this.newImgsToUploadIdx.push(obj.cameraNum);
      this.$nextTick(() => {
        this.placeCanvas(cameraNum);
      });
    },
    sendToCamera(arr) {
      // eslint-disable-next-line prefer-destructuring
      this.currType = arr[0];
      // eslint-disable-next-line prefer-destructuring
      this.cameraNum = arr[1];
      this.isCameraOpen = true;
    },
    async save() {
      try {
        // eslint-disable-next-line array-callback-return
        const arr = await Promise.all(
          this.imgs.map(async (img, idx) => {
            if (this.newImgsToUploadIdx.includes(img.cameraNum)) {
              const url = await AccidentService.uploadImage(img.imgUrl);
              img.imgUrl = url.data;
              return {
                imgCanvas: img.imgCanvas.toDataURL(),
                imgUrl: img.imgUrl,
                cameraNum: this.imgsAddedIdx[idx],
                ImageType: img.ImageType || this.currType,
              };
            }
            let type = this.currType;
            if (img.cameraNum === 0) type = 2;
            else if (img.cameraNum === 1) type = 3;
            else if (img.cameraNum === 2) type = 1;
            else if (img.cameraNum === 3 || 4 || 5 || 6) {
              this.localhostName === "myDamage" ? (type = 0) : (type = 4);
            }
            return {
              imgCanvas: img.imgCanvas,
              imgUrl: img.imgUrl,
              cameraNum: this.imgsAddedIdx[idx],
              ImageType: type,
            };
          })
        );
        const toUpload = { r: arr, localhostName: this.localhostName };
        this.$emit("uploadAndClose", toUpload);
      } catch (err) {
        console.log("in catch", err);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.upload-accident-photos {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  position: fixed;
  background-color: #7aa9b1;
  // border: 1px black solid;
  // width: 98vw;
  // height: 98vh;
  // margin: 1vh 1vw;
  width: 100vw;
  height: 100vh;
  top: 0;
  right: 0;
  z-index: 10;
   .header-btns-container {
    // height: 7vh;
    height: 10vh;
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    top: 0;
    position: absolute;
    padding: 0 20px;
    border-bottom: 1px solid black;
    button {
      color: white;
      border: none;
      background-color: inherit;
      font-size: larger;
      font-weight: 400;
    }
  }
  .opt-btns-container {
    display: flex;
    width: 100%;
    // height: 90%;
    // margin-top: 10%;
    height: 90vh;
    margin-top: 10vh;
    flex-direction: column;
    align-content: center;
    align-items: center;
    overflow-y: auto;
    // justify-content: space-evenly;
    justify-content: flex-start;
    padding: 20px 0 110px 0;

    & > div {
      display: flex;
      flex-direction: column;
      width: 200px;
      height: 200px;
      align-items: center;
      color: white;
      & button {
        // width: 65%;
        // height: 65%;
        width: 100px;
        height: 108px;
        background-color: cadetblue;
        border: none;
      }
      ::v-deep button {
        color: white;
      }
    }
  }
  .damage-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }

  canvas {
    border-radius: 4px;
    cursor: pointer;
  }
}
</style>
