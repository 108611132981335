var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "PhoneAccident__Component" },
    [
      _vm.frame === 0
        ? _c("div", { staticClass: "frame-0" }, [
            _c("h1", [_vm._v("שלום נהג יקר")]),
            _c("h1", [_vm._v("הינך עומד למלא טופס תאונה")]),
            _c("h1", [_vm._v("יש למלא מספר טלפון כדי להמשיך")]),
            _c(
              "div",
              { staticClass: "frame-0-input-btn" },
              [
                _c("FieldInlineText", {
                  attrs: {
                    label: "הכנס מספר טלפון:",
                    readOnly: false,
                    type: "tel",
                    borderBottom: "1px solid white"
                  },
                  on: { onChange: _vm.onChange },
                  model: {
                    value: _vm.phoneNumber,
                    callback: function($$v) {
                      _vm.phoneNumber = $$v
                    },
                    expression: "phoneNumber"
                  }
                }),
                _c("b-button", {
                  attrs: { label: "המשך" },
                  on: { click: _vm.varifyPhone }
                })
              ],
              1
            )
          ])
        : _vm._e(),
      _vm.frame === 1
        ? _c("div", { staticClass: "frame-1" }, [
            _c("h1", [
              _vm._v(
                "נא הכנס קוד שקיבלת בהודעה למספר נייד " +
                  _vm._s(_vm.model.phoneNumber)
              )
            ]),
            _c(
              "div",
              { staticClass: "frame-1-input-btn" },
              [
                _c("FieldInlineText", {
                  attrs: {
                    label: "הכנס קוד:",
                    readOnly: false,
                    type: "tel",
                    borderBottom: "1px solid white"
                  },
                  on: { onChange: _vm.onChange },
                  model: {
                    value: _vm.sms,
                    callback: function($$v) {
                      _vm.sms = $$v
                    },
                    expression: "sms"
                  }
                }),
                _c(
                  "div",
                  { staticClass: "frame-1-btns-container" },
                  [
                    _c("b-button", {
                      attrs: { label: "המשך" },
                      on: { click: _vm.varifySms }
                    }),
                    _c("a", { on: { click: _vm.varifyPhone } }, [
                      _vm._v("שלח שוב")
                    ])
                  ],
                  1
                )
              ],
              1
            )
          ])
        : _vm._e(),
      _vm.frame === 2
        ? _c("div", { staticClass: "frame-2" }, [
            _c("h1", [_vm._v(_vm._s(_vm.driverName) + " נא בחר סוג תאונה")]),
            _c(
              "div",
              { staticClass: "accident-types" },
              _vm._l(_vm.accidentOpt, function(accident) {
                return _c(
                  "div",
                  { key: accident.Value },
                  [
                    _c("label", { attrs: { for: "" } }, [
                      _vm._v(" " + _vm._s(accident.Text) + " ")
                    ]),
                    _c("b-button", {
                      style: {
                        backgroundColor: _vm.getBackground(
                          accident.Value,
                          "color"
                        ),
                        backgroundImage: _vm.getBackground(
                          accident.Value,
                          "icon"
                        )
                      },
                      on: {
                        click: function($event) {
                          return _vm.onSelect(accident)
                        }
                      }
                    })
                  ],
                  1
                )
              }),
              0
            )
          ])
        : _vm._e(),
      _vm.frame === 3
        ? _c(
            "div",
            { staticClass: "frame-3" },
            [
              _c("b-button", {
                attrs: { label: "חזור" },
                on: {
                  click: function($event) {
                    _vm.frame = 2
                  }
                }
              }),
              _c(
                "h1",
                { staticStyle: { "text-align": "center", color: "white" } },
                [_vm._v(" " + _vm._s(this.model.AccidentType.Text) + " ")]
              ),
              _c("FieldInlineText", {
                attrs: {
                  label: "מספר רכב",
                  readOnly: true,
                  value: _vm.getPlateNumber
                },
                on: { onChange: _vm.onChange }
              }),
              _c("FieldInlineDateTime", {
                attrs: {
                  label: "תאריך ושעת האירוע",
                  readOnly: _vm.readOnly,
                  position: "is-bottom-left",
                  borderBottom: true
                },
                on: { onChange: _vm.onChange },
                model: {
                  value: _vm.model.Date,
                  callback: function($$v) {
                    _vm.$set(_vm.model, "Date", $$v)
                  },
                  expression: "model.Date"
                }
              }),
              _c("FieldInlineText", {
                attrs: {
                  label: "מיקום ההתרחשות",
                  readOnly: _vm.readOnly,
                  borderBottom: "01px solid white"
                },
                on: { onChange: _vm.onChange },
                model: {
                  value: _vm.model.Address,
                  callback: function($$v) {
                    _vm.$set(_vm.model, "Address", $$v)
                  },
                  expression: "model.Address"
                }
              }),
              _c("b-button", {
                staticClass: "my-location-btn",
                attrs: { "icon-right": "map-marker" },
                on: { click: _vm.getMyCurrLoc }
              }),
              _c(
                "div",
                { staticClass: "btns-pics-containar" },
                [
                  !_vm.isLoading && _vm.uploadeFiles.includes(0)
                    ? _c("b-button", {
                        attrs: {
                          label: "תמונות נזק- רכב שלי",
                          "icon-left": _vm.validateMyPhotos ? "check" : ""
                        },
                        on: {
                          click: function($event) {
                            return _vm.openMyDamageModal([1, 3])
                          }
                        }
                      })
                    : _vm._e(),
                  !_vm.isLoading && _vm.uploadeFiles.includes(1)
                    ? _c("b-button", {
                        attrs: {
                          label: "פרטי רכב צד ג'",
                          "icon-left": _vm.validateThirdSideDetailsPhotos
                            ? "check"
                            : ""
                        },
                        on: {
                          click: function($event) {
                            return _vm.openMyDamageModal([0, 1, 2])
                          }
                        }
                      })
                    : _vm._e(),
                  !_vm.isLoading && _vm.uploadeFiles.includes(2)
                    ? _c("b-button", {
                        attrs: {
                          label: "תמונות נזק צד ג'",
                          "icon-left": _vm.validateThirdSideDamagePhotos
                            ? "check"
                            : ""
                        },
                        on: {
                          click: function($event) {
                            return _vm.openMyDamageModal([3])
                          }
                        }
                      })
                    : _vm._e()
                ],
                1
              ),
              _c("FieldInlineTextArea", {
                attrs: { label: "תיאור המקרה", readOnly: _vm.readOnly },
                on: { onChange: _vm.onChange },
                model: {
                  value: _vm.model.Description,
                  callback: function($$v) {
                    _vm.$set(_vm.model, "Description", $$v)
                  },
                  expression: "model.Description"
                }
              }),
              !_vm.isLoading
                ? _c("b-button", {
                    staticStyle: { margin: "0 0 0 20px" },
                    attrs: {
                      "icon-left": _vm.isSent ? "check" : "",
                      label: "שמור"
                    },
                    on: { click: _vm.saveAndUpload }
                  })
                : _vm._e(),
              !_vm.isLoading && _vm.isReadyToSign
                ? _c("b-button", {
                    attrs: { label: "הוסף חתימה" },
                    on: { click: _vm.toSign }
                  })
                : _vm._e()
            ],
            1
          )
        : _vm._e(),
      _vm.frame === 4
        ? _c("div", { staticClass: "frame-4" }, [
            _c("h1", [_vm._v("תאונה הועלתה בהצלחה")])
          ])
        : _vm._e(),
      _vm.isUploadAccidentPhotos
        ? _c("UploadAccidentPhotos", {
            attrs: {
              uploadCase: _vm.uploadCase,
              uploadeFiles: _vm.uploadeFiles,
              AccidentType: _vm.model.AccidentType
            },
            on: {
              uploadAndClose: _vm.uploadAndClose,
              close: function($event) {
                _vm.isUploadAccidentPhotos = false
              }
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }