<template>
  <div class="web-camera-container">
    <div class="" :class="isLoading ? 'close-loading' : 'close-camera-btn'">
      <button type="button" class="button is-rounded" @click="closeCamera">
        <!-- <span>סגור מצלמה</span> -->
        <b-icon icon="close" size="is-large"> </b-icon>
      </button>
    </div>

    <div v-show="isCameraOpen && isLoading" class="camera-loading">
      <ul class="loader-circle">
        <li></li>
        <li></li>
        <li></li>
      </ul>
    </div>

    <div
      v-if="isCameraOpen"
      v-show="!isLoading"
      class="camera-box"
      :class="{ flash: isShotPhoto }"
    >
      <div class="camera-shutter" :class="{ flash: isShotPhoto }"></div>

      <video v-show="!isPhotoTaken" ref="camera" autoplay playsinline></video>

      <canvas
        v-show="isPhotoTaken"
        id="photoTaken"
        ref="canvas"
        :width="324"
        :height="423"
      ></canvas>
    </div>
    <div class="camera-btns-container">
      <div v-if="isCameraOpen && !isLoading" class="camera-shoot">
        <button type="button" class="button" @click="takePhoto">
          <b-icon size="is-large" icon="camera"> </b-icon>
        </button>
      </div>
      <div v-if="isPhotoTaken && isCameraOpen" class="camera-choose-btn">
        <button @click="usePhoto">השתמש בתמונה</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["isCamera", "currType", "cameraNum"],
  data() {
    return {
      isCameraOpen: false,
      isPhotoTaken: false,
      isShotPhoto: false,
      isLoading: false,
      link: "#",
      canvas: null,
    };
  },
  created() {
    this.openCamera();
  },
  methods: {
    openCamera() {
      this.isCameraOpen = true;
      this.createCameraElement();
    },
    closeCamera() {
      this.isCameraOpen = false;
      this.isPhotoTaken = false;
      this.isShotPhoto = false;
      this.stopCameraStream();
      this.$emit("closeCamera");
    },

    createCameraElement() {
      this.isLoading = true;
      // eslint-disable-next-line no-multi-assign
      const constraints = (window.constraints = {
        audio: false,
        video: {
          facingMode: "environment",
        },
      });

      navigator.mediaDevices
        .getUserMedia(constraints)
        .then((stream) => {
          this.isLoading = false;
          this.$refs.camera.srcObject = stream;
        })
        .catch((error) => {
          console.log("error:", error);
          this.$buefy.toast.open({
            duration: 8000,
            message: `לא ניתן לפתוח את המצלמה<br>נא אפשר בהגדרות הטלפון את השימוש`,
            position: "is-bottom",
            type: "is-danger",
          });
          this.isLoading = false;
        });
    },

    stopCameraStream() {
      try {
        const tracks = this.$refs.camera.srcObject.getTracks();
        tracks.forEach((track) => {
          track.stop();
        });
      } catch {
        console.log("in catch");
      }
    },
    usePhoto() {
      this.$refs.canvas.toBlob((blob) => {
        const formData = new FormData();
        formData.append("file", blob, "image.jpg");
        const addPhotoObj = {
          // imgCanvas: this.$refs.camera,
          imgCanvas: this.$refs.canvas,
          imgUrl: formData,
          ImageType: this.currType,
          cameraNum: this.cameraNum,
        };
        this.$emit("addPhotoObj", addPhotoObj);
      }, "image/jpeg");
    },
    takePhoto() {
      if (!this.isPhotoTaken) {
        this.isShotPhoto = true;

        const FLASH_TIMEOUT = 50;

        setTimeout(() => {
          this.isShotPhoto = false;
        }, FLASH_TIMEOUT);
      }

      this.isPhotoTaken = !this.isPhotoTaken;

      const context = this.$refs.canvas.getContext("2d");
      context.drawImage(this.$refs.camera, 0, 0, 324, 423);
    },
  },
};
</script>

<style lang="scss" scoped>
.web-camera-container {
  position: absolute;
  top: 0;
  right: 0;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100vw;
  height: 100vh;
  background-color: #7aa9b1;
  .close-loading {
    margin-bottom: 50px;
    z-index: 1;
    button {
      padding: 0;
      transform: translateX(13px);
      background-color: transparent;
      border: none;
    }
  }
  .close-camera-btn {
    margin-bottom: 2rem;
    align-self: end;
    button {
      border: none;
      background-color: transparent;
    }
  }

  .camera-box {
    width: 100%;
    height: 100%;
    // display: contents;
    display: flex;
    align-items: center;
    justify-content: center;
    .camera-shutter {
      opacity: 0;
      width: 450px;
      height: 337.5px;
      position: absolute;
      &.flash {
        opacity: 1;
      }
    }
  }
  .camera-btns-container {
    display: flex;
    .camera-shoot,
    .camera-choose-btn {
      margin: 1rem 0;
      padding: 10px;
      button {
        height: 70px;
        width: 70px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 100%;
        border: none;
        img {
          height: 35px;
          object-fit: cover;
        }
      }
    }
  }
  .camera-loading {
    overflow: hidden;
    height: 100%;
    position: absolute;
    width: 100%;
    min-height: 150px;
    margin: 3rem 0 0 -1.2rem;

    ul {
      height: 100%;
      position: absolute;
      width: 100%;
      z-index: 999999;
      margin: 0;
    }

    .loader-circle {
      display: block;
      height: 14px;
      margin: 0 auto;
      top: 50%;
      left: 100%;
      transform: translateY(-50%);
      transform: translateX(-50%);
      position: absolute;
      width: 100%;
      padding: 0;

      li {
        display: block;
        float: left;
        width: 10px;
        height: 10px;
        line-height: 10px;
        padding: 0;
        position: relative;
        margin: 0 0 0 4px;
        background: #999;
        animation: preload 1s infinite;
        top: -50%;
        border-radius: 100%;

        &:nth-child(2) {
          animation-delay: 0.2s;
        }

        &:nth-child(3) {
          animation-delay: 0.4s;
        }
      }
    }
  }

  @keyframes preload {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0.4;
    }
    100% {
      opacity: 1;
    }
  }
}
</style>
